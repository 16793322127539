import actions from './actions';

const initState = {
  pending: true,
  interventions: [],
  error: null,
};

export function interventionsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_INTERVENTIONS_PENDING:
      return { ...state, pending: true };
    case actions.FETCH_INTERVENTIONS_SUCCESS:
      return {
        ...state,
        pending: false,
        interventions: action.payload,
      };
    case actions.FETCH_INTERVENTIONS_ERROR:
      return {
        ...state,
        interventions: null,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const getInterventions = state => state.interventions;
export const getInterventionsPending = state => state.pending;
export const getInterventionsError = state => state.error;
