import actions from './actions';

const initState = {
  pageSize: 15,
  pending: true,
  users: [],
  usersSearched: null,
  search: false,
  error: null,
  user: null,
  createPending: false,
  createdUser: null,
};

export function usersReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_USERS_PENDING:
      if (action.payload) {
        return { ...state, search: true, pending: true };
      } else {
        return { ...state, search: false, pending: true };
      }
    case actions.FETCH_USERS_SUCCESS:
      return {
        ...state,
        pending: false,
        search: false,
        users: action.payload.results,
        total: action.payload.count,
      };
    case actions.FETCH_USERS_ERROR:
      return {
        ...state,
        users: [],
        usersSearched: null,
        error: action.payload,
        search: false,
      };

    case actions.FETCH_USER_PENDING:
      return { ...state, search: false, userPending: true };

    case actions.FETCH_USER_SUCCESS:
      return {
        ...state,
        userPending: false,
        user: action.payload,
      };

    case actions.FETCH_USER_ERROR:
      return {
        ...state,
        users: [],
        usersSearched: null,
        error: action.payload,
      };

    case actions.CREATE_USER_PENDING:
      return { ...state, createPending: true };

    case actions.CREATE_USER_SUCCESS:
      return {
        ...state,
        createPending: false,
        createdUser: action.payload,
      };

    case actions.CREATE_USER_ERROR:
      return {
        ...state,
        createPending: false,
        createError: action.payload,
        createdUser: null,
      };

    case actions.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };

    default:
      return state;
  }
}

export const getPageSize = state => state.pageSize;
export const getUsers = state => state.users;
export const getUsersPending = state => state.pending;
export const getUsersSearched = state => state.usersSearched;
export const getUsersSearch = state => state.search;
export const getUsersError = state => state.error;
export const getCreateUserPending = state => state.createPending;
export const getCreateUserError = state => state.createError;
export const getCreatedUser = state => state.createdUser;
export const getTotalUsers = state => state.total;
export const getUser = state => state.user;
export const getUserError = state => state.error;
export const getUserPending = state => state.pending;
