import { branch_vars } from './branch_variables.js';

export default {
  siteName: 'ZEROVENTI EXE.GESI',
  siteIcon: 'ion-flash',
  footerText: `ZEROVENTI EXE.GESI © ${new Date().getFullYear()}`,
  enableAnimatedRoute: false,
  // apiUrl: 'https://ux.zeroventiexegesi.it/api/',
  apiUrl: branch_vars.apiUrl,
  google: {
    analyticsKey: 'UA-xxxxxxxxx-1',
  },
  dashboard: '/dashboard',
  // commitVersion:
  //   process.env.REACT_APP_GIT_COMMIT &&
  //   process.env.REACT_APP_GIT_COMMIT.length === 40
  //     ? process.env.REACT_APP_GIT_COMMIT.substring(0, 7)
  //     : 'Local Dev',
  // commitUrl: `https://github.com/doppiozerosrl/vmexegesi-frontend/commit/${process.env.REACT_APP_GIT_COMMIT}`,
  // gitBranch: process.env.REACT_APP_GIT_BRANCH || 'Local',
  // version: process.env.REACT_APP_VERSION,
};
