import actions from './actions';
import jwtDecode from 'jwt-decode';

const initState = {
  idToken: null,
  error: null,
  username: null,
  user_id: null,
  groups: [],
  name: { first_name: undefined, last_name: undefined },
  email: undefined,
};

export function authReducer(state = initState, action) {
  switch (action.type) {
    case actions.LOGIN_SUCCESS:
      const { username, user_id, groups, name, email } = jwtDecode(
        action.token
      );
      return {
        ...state,
        idToken: action.token,
        username,
        user_id,
        groups: groups || [],
        name,
        email,
      };
    case actions.LOGIN_REQUEST_SUCCESS:
      return {
        ...state,
        idToken: action.payload.token,
        username: action.payload.profile.username,
      };
    case actions.LOGIN_REQUEST_START:
    case actions.LOGIN_REQUEST_FAILURE:
      return {
        ...state,
        idToken: null,
        error: action.payload,
      };
    case actions.LOGOUT:
      return initState;
    default:
      return state;
  }
}

export const getToken = state => state.idToken;
export const getUser = state => state.username;
export const getName = state => state.name;
export const getUserId = state => state.user_id;
export const getUserGroups = state => state.groups;
