const actions = {
  FETCH_ELEVATORS_PENDING: 'FETCH_ELEVATORS_PENDING',
  FETCH_ELEVATORS_SUCCESS: 'FETCH_ELEVATORS_SUCCESS',
  FETCH_ELEVATORS_ERROR: 'FETCH_ELEVATORS_ERROR',
  FETCH_ELEVATOR_PENDING: 'FETCH_ELEVATOR_PENDING',
  FETCH_ELEVATOR_SUCCESS: 'FETCH_ELEVATOR_SUCCESS',
  FETCH_ELEVATOR_ERROR: 'FETCH_ELEVATOR_ERROR',
  CREATE_ELEVATOR_PENDING: 'CREATE_ELEVATOR_PENDING',
  CREATE_ELEVATOR_SUCCESS: 'CREATE_ELEVATOR_SUCCESS',
  CREATE_ELEVATOR_ERROR: 'CREATE_ELEVATOR_ERROR',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',

  fetchElevatorsPending: (search = false) => {
    return {
      type: actions.FETCH_ELEVATORS_PENDING,
      payload: search,
    };
  },

  fetchElevatorsSuccess: results => {
    return {
      type: actions.FETCH_ELEVATORS_SUCCESS,
      payload: results,
    };
  },

  fetchElevatorsError: error => {
    return {
      type: actions.FETCH_ELEVATORS_ERROR,
      payload: error,
    };
  },

  fetchElevatorPending: (search = false) => {
    return {
      type: actions.FETCH_ELEVATOR_PENDING,
      payload: search,
    };
  },

  fetchElevatorSuccess: results => {
    return {
      type: actions.FETCH_ELEVATOR_SUCCESS,
      payload: results,
    };
  },

  fetchElevatorError: error => {
    return {
      type: actions.FETCH_ELEVATOR_ERROR,
      payload: error,
    };
  },

  createElevatorPending: () => {
    return {
      type: actions.CREATE_ELEVATOR_PENDING,
    };
  },

  createElevatorSuccess: results => {
    return {
      type: actions.CREATE_ELEVATOR_SUCCESS,
      payload: results,
    };
  },

  createElevatorError: error => {
    return {
      type: actions.CREATE_ELEVATOR_ERROR,
      payload: error,
    };
  },

  setPageSize: pageSize => {
    return {
      type: actions.SET_PAGE_SIZE,
      payload: pageSize,
    };
  },
};

export default actions;
