const actions = {
  CHECK_AUTHORIZATION: 'CHECK_AUTHORIZATION',
  LOGOUT: 'LOGOUT',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  LOGIN_REQUEST_START: 'LOGIN_REQUEST_START',
  LOGIN_REQUEST_SUCCESS: 'LOGIN_REQUEST_SUCCESS',
  LOGIN_REQUEST_FAILURE: 'LOGIN_REQUEST_FAILURE',

  checkAuthorization: () => ({ type: actions.CHECK_AUTHORIZATION }),

  login: credentials => ({
    type: actions.LOGIN_REQUEST_START,
    payload: credentials,
  }),
  loginRequestSuccess: credentials => ({
    type: actions.LOGIN_SUCCESS,
    payload: credentials,
    token: credentials.token,
  }),
  loginRequestFailure: error => ({
    type: actions.LOGIN_REQUEST_FAILURE,
    payload: error,
  }),
  logout: () => ({
    type: actions.LOGOUT,
  }),
};
export default actions;
