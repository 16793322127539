import actions from './actions';

const initState = {
  pending: true,
  requests: [],
  requestsSearched: null,
  search: false,
  error: null,
  selectedRequest: null,
  total: 0,
  request: null,
  requestPending: false,
  requestErorr: null,
  backend_version: '',
  stats: {},
};

export function requestsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_REQUESTS_PENDING:
      if (action.payload) {
        return {
          ...state,
          search: true,
          pending: true,
          total: initState.total,
        };
      } else {
        return {
          ...state,
          search: false,
          pending: true,
          total: initState.total,
        };
      }
    case actions.FETCH_REQUESTS_SUCCESS:
      if (state.search) {
        return {
          ...state,
          pending: false,
          requestsSearched: action.payload.results,
          total: action.payload.count,
        };
      } else {
        return {
          ...state,
          pending: false,
          requests: action.payload.results,
          total: action.payload.count,
          search: false,
        };
      }
    case actions.FETCH_REQUESTS_ERROR:
      return {
        ...state,
        requests: [],
        requestsSearched: null,
        error: action.payload,
        search: false,
      };

    case actions.FETCH_REQUEST_PENDING:
      return {
        ...state,
        requestPending: true,
      };
    case actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        requestPending: false,
        request: action.payload,
      };
    case actions.FETCH_REQUEST_ERROR:
      return {
        ...state,
        requestError: action.payload,
        request: initState.request,
      };

    case actions.SET_SELECTED_REQUEST:
      return {
        ...state,
        selectedRequest: action.payload,
      };

    case actions.SUBMIT_REQUEST_PENDING:
      return { ...state, submitPending: true };
    case actions.SUBMIT_REQUEST_SUCCESS:
      return {
        ...state,
        submitPending: false,
        submittedRequest: action.payload,
      };
    case actions.SUBMIT_REQUEST_ERROR:
      return {
        ...state,
        submitPending: false,
        submitError: action.payload,
        submittedRequest: null,
      };

    case actions.CREATE_REQUEST_PENDING:
      return { ...state, submitPending: true };

    case actions.CREATE_REQUEST_SUCCESS:
      return {
        ...state,
        submitPending: false,
        submittedRequest: action.payload,
      };
    case actions.CREATE_REQUEST_ERROR:
      return {
        ...state,
        submitPending: false,
        submitError: action.payload,
        submittedRequest: null,
      };

    case actions.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };
    case actions.SET_VERSION:
      return {
        ...state,
        backend_version: action.payload,
      };

    case actions.SET_STATS:
      return {
        ...state,
        stats: action.payload,
      };
    case actions.CLEAR_REQUESTS:
      const { requests, pending, error } = initState;
      return {
        ...state,
        requests,
        pending,
        error,
      };
    default:
      return state;
  }
}

export const getRequest = state => state.request;
export const getRequestPending = state => state.requestPending;
export const getRequestError = state => state.requestError;
export const getRequests = state => state.requests;
export const getPageSize = state => state.pageSize;
export const getRequestsPending = state => state.pending;
export const getRequestsSearched = state => state.requestsSearched;
export const getRequestsSearch = state => state.search;
export const getRequestsError = state => state.error;
export const getSelectedRequest = state => state.selectedRequest;
export const getSubmitRequestPending = state => state.submitPending;
export const getSubmitRequestError = state => state.submitError;
export const getSubmittedRequest = state => state.submittedRequest;
export const getTotalRequest = state => state.total;
export const getBackendVersion = state => state.backend_version;
export const getStats = state => state.stats;
