import actions from './actions';

const initState = {
  pending: true,
  callers: [],
  error: null,
  caller: {
    name: undefined,
    phoneNumber: undefined,
    callerType: 'Utente',
  },
};

export function callersReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_CALLERS_PENDING:
      return { ...state, pending: true };
    case actions.FETCH_CALLERS_SUCCESS:
      return {
        ...state,
        pending: false,
        callers: action.payload,
      };
    case actions.FETCH_CALLERS_ERROR:
      return {
        ...state,
        callers: [],
        error: action.payload,
      };
    case actions.RESET_CALLERS_LIST:
      return {
        ...state,
        callers: [],
      };
    case actions.FETCH_CALLER_PENDING:
      return { ...state, fetchCallerPending: true };
    case actions.FETCH_CALLER_SUCCESS:
      return {
        ...state,
        fetchCallerPending: false,
        caller: action.payload,
      };
    case actions.FETCH_CALLER_ERROR:
      return {
        ...state,
        caller: initState.caller,
        fetchCallerError: action.payload,
      };
    default:
      return state;
  }
}

export const getCallers = state => state.callers;
export const getCallersPending = state => state.pending;
export const getCallersError = state => state.error;

export const getCaller = state => state.caller;
export const getCallerPending = state => state.fetchCallerPending;
export const getCallerError = state => state.fetchCallerError;
