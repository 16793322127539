const actions = {
  SEND_NOTIFICATION: 'SEND_NOTIFICATION',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',

  sendNotification: notification => {
    return {
      type: actions.SEND_NOTIFICATION,
      payload: notification,
    };
  },

  deleteNotification: notification => {
    return {
      type: actions.DELETE_NOTIFICATION,
      payload: notification,
    };
  },
};

export default actions;
