import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import themes from '@iso/config/theme/theme.config';
import AppLocale from '@iso/config/translation';
import authHelper from '@iso/lib/helpers/authHelper';
import { logout } from './redux/authentication/saga';
import notification from '@iso/components/Notification';

export default ({ children }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    authHelper.checkDemoPage(localStorage.getItem('id_token')).then(result => {
      if (result.error) {
        if (
          !localStorage.getItem('id_token') &&
          result.error === 'Token expired'
        ) {
          notification('info', 'Effettua il login');
        } else {
          notification('error', result.error);
        }
        dispatch(logout);
      }
    });
  }, [dispatch]);

  const { locale } = useSelector(state => state.LanguageSwitcher.language);
  const { themeName } = useSelector(state => state.ThemeSwitcher.changeThemes);
  const currentAppLocale = AppLocale[locale];
  return (
    <ConfigProvider locale={currentAppLocale.antd}>
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <ThemeProvider theme={themes[themeName]}>{children}</ThemeProvider>
      </IntlProvider>
    </ConfigProvider>
  );
};
