import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { RestLink } from 'apollo-link-rest';
import siteConfig from './site.config';
import { ApolloLink } from 'apollo-link';

// setup your `RestLink` with your endpoint
const authLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem('id_token');
  operation.setContext({
    headers: { authorization: token ? `Bearer ${token}` : '' },
  });
  return forward(operation);
});

const restLink = new RestLink({
  uri: siteConfig.apiUrl,
});

// setup your client
export const client = new ApolloClient({
  link: authLink.concat(restLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});
