const actions = {
  FETCH_USERS_PENDING: 'FETCH_USERS_PENDING',
  FETCH_USERS_SUCCESS: 'FETCH_USERS_SUCCESS',
  FETCH_USERS_ERROR: 'FETCH_USERS_ERROR',
  FETCH_USER_PENDING: 'FETCH_USER_PENDING',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  FETCH_USER_ERROR: 'FETCH_USER_ERROR',
  CREATE_USER_PENDING: 'CREATE_USER_PENDING',
  CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
  CREATE_USER_ERROR: 'CREATE_USER_ERROR',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',

  fetchUsersPending: (search = false) => {
    return {
      type: actions.FETCH_USERS_PENDING,
      payload: search,
    };
  },

  fetchUsersSuccess: results => {
    return {
      type: actions.FETCH_USERS_SUCCESS,
      payload: results,
    };
  },

  fetchUsersError: error => {
    return {
      type: actions.FETCH_USERS_ERROR,
      payload: error,
    };
  },

  fetchUserPending: (search = false) => {
    return {
      type: actions.FETCH_USER_PENDING,
      payload: search,
    };
  },

  fetchUserSuccess: results => {
    return {
      type: actions.FETCH_USER_SUCCESS,
      payload: results,
    };
  },

  fetchUserError: error => {
    return {
      type: actions.FETCH_USER_ERROR,
      payload: error,
    };
  },

  createUserPending: () => {
    return {
      type: actions.CREATE_USER_PENDING,
    };
  },

  createUserSuccess: results => {
    return {
      type: actions.CREATE_USER_SUCCESS,
      payload: results,
    };
  },

  createUserError: error => {
    return {
      type: actions.CREATE_USER_ERROR,
      payload: error,
    };
  },

  setPageSize: pageSize => {
    return {
      type: actions.SET_PAGE_SIZE,
      payload: pageSize,
    };
  },
};

export default actions;
