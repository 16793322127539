const actions = {
  FETCH_REQUESTS_PENDING: 'FETCH_REQUESTS_PENDING',
  FETCH_REQUESTS_SUCCESS: 'FETCH_REQUESTS_SUCCESS',
  FETCH_REQUESTS_ERROR: 'FETCH_REQUESTS_ERROR',
  FETCH_REQUEST_PENDING: 'FETCH_REQUEST_PENDING',
  FETCH_REQUEST_SUCCESS: 'FETCH_REQUEST_SUCCESS',
  FETCH_REQUEST_ERROR: 'FETCH_REQUEST_ERROR',
  SET_SELECTED_REQUEST: 'SET_SELECTED_REQUEST',
  SUBMIT_REQUEST_PENDING: 'SUBMIT_REQUEST_PENDING',
  SUBMIT_REQUEST_SUCCESS: 'SUBMIT_REQUEST_SUCCESS',
  SUBMIT_REQUEST_ERROR: 'SUBMIT_REQUEST_ERROR',
  CREATE_REQUEST_PENDING: 'CREATE_REQUEST_PENDING',
  CREATE_REQUEST_SUCCESS: 'CREATE_REQUEST_SUCCESS',
  CREATE_REQUEST_ERROR: 'CREATE_REQUEST_ERROR',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',
  SET_VERSION: 'SET_VERSION',
  SET_STATS: 'SET_STATS',
  CLEAR_REQUESTS: 'CLEAR_REQUESTS',

  fetchRequestsPending: (search = false) => {
    return {
      type: actions.FETCH_REQUESTS_PENDING,
      payload: search,
    };
  },

  fetchRequestsSuccess: results => {
    return {
      type: actions.FETCH_REQUESTS_SUCCESS,
      payload: results,
    };
  },

  fetchRequestsError: error => {
    return {
      type: actions.FETCH_REQUESTS_ERROR,
      payload: error,
    };
  },

  fetchRequestPending: (search = false) => {
    return {
      type: actions.FETCH_REQUEST_PENDING,
      payload: search,
    };
  },

  fetchRequestSuccess: results => {
    return {
      type: actions.FETCH_REQUEST_SUCCESS,
      payload: results,
    };
  },

  fetchRequestError: error => {
    return {
      type: actions.FETCH_REQUEST_ERROR,
      payload: error,
    };
  },

  setSelectedRequest: request => {
    return {
      type: actions.SET_SELECTED_REQUEST,
      payload: request,
    };
  },

  submitRequestPending: () => {
    return {
      type: actions.SUBMIT_REQUEST_PENDING,
    };
  },

  submitRequestSuccess: results => {
    return {
      type: actions.SUBMIT_REQUEST_SUCCESS,
      payload: results,
    };
  },

  submitRequestError: error => {
    return {
      type: actions.SUBMIT_REQUEST_ERROR,
      payload: error,
    };
  },

  createRequestPending: () => {
    return {
      type: actions.CREATE_REQUEST_PENDING,
    };
  },

  createRequestSuccess: results => {
    return {
      type: actions.CREATE_REQUEST_SUCCESS,
      payload: results,
    };
  },

  createRequestError: error => {
    return {
      type: actions.CREATE_REQUEST_ERROR,
      payload: error,
    };
  },

  setPageSize: pageSize => {
    return {
      type: actions.SET_PAGE_SIZE,
      payload: pageSize,
    };
  },

  setVersion: version => {
    return {
      type: actions.SET_VERSION,
      payload: version,
    };
  },

  setStats: stats => {
    return {
      type: actions.SET_STATS,
      payload: stats,
    };
  },

  clearRequests: () => {
    return {
      type: actions.CLEAR_REQUESTS,
    };
  },
};

export default actions;
