const actions = {
  FETCH_TECHNICIANS_PENDING: 'FETCH_TECHNICIANS_PENDING',
  FETCH_TECHNICIANS_SUCCESS: 'FETCH_TECHNICIANS_SUCCESS',
  FETCH_TECHNICIANS_ERROR: 'FETCH_TECHNICIANS_ERROR',
  FETCH_TECHNICIAN_PENDING: 'FETCH_TECHNICIAN_PENDING',
  FETCH_TECHNICIAN_SUCCESS: 'FETCH_TECHNICIAN_SUCCESS',
  FETCH_TECHNICIAN_ERROR: 'FETCH_TECHNICIAN_ERROR',
  CREATE_TECHNICIAN_PENDING: 'CREATE_TECHNICIAN_PENDING',
  CREATE_TECHNICIAN_SUCCESS: 'CREATE_TECHNICIAN_SUCCESS',
  CREATE_TECHNICIAN_ERROR: 'CREATE_TECHNICIAN_ERROR',
  DELETE_TECHNICIAN_PENDING: 'DELETE_TECHNICIAN_PENDING',
  DELETE_TECHNICIAN_SUCCESS: 'DELETE_TECHNICIAN_SUCCESS',
  DELETE_TECHNICIAN_ERROR: 'DELETE_TECHNICIAN_ERROR',
  SET_PAGE_SIZE: 'SET_PAGE_SIZE',

  fetchTechniciansPending: (search = false) => {
    return {
      type: actions.FETCH_TECHNICIANS_PENDING,
      payload: search,
    };
  },

  fetchTechniciansSuccess: results => {
    return {
      type: actions.FETCH_TECHNICIANS_SUCCESS,
      payload: results,
    };
  },

  fetchTechniciansError: error => {
    return {
      type: actions.FETCH_TECHNICIANS_ERROR,
      payload: error,
    };
  },

  fetchTechnicianPending: (search = false) => {
    return {
      type: actions.FETCH_TECHNICIAN_PENDING,
      payload: search,
    };
  },

  fetchTechnicianSuccess: results => {
    return {
      type: actions.FETCH_TECHNICIAN_SUCCESS,
      payload: results,
    };
  },

  fetchTechnicianError: error => {
    return {
      type: actions.FETCH_TECHNICIAN_ERROR,
      payload: error,
    };
  },

  createTechnicianPending: () => {
    return {
      type: actions.CREATE_TECHNICIAN_PENDING,
    };
  },

  createTechnicianSuccess: results => {
    return {
      type: actions.CREATE_TECHNICIAN_SUCCESS,
      payload: results,
    };
  },

  createTechnicianError: error => {
    return {
      type: actions.CREATE_TECHNICIAN_ERROR,
      payload: error,
    };
  },

  deleteTechnicianPending: () => {
    return {
      type: actions.DELETE_TECHNICIAN_PENDING,
    };
  },

  deleteTechnicianSuccess: results => {
    return {
      type: actions.DELETE_TECHNICIAN_SUCCESS,
      payload: results,
    };
  },

  deleteTechnicianError: error => {
    return {
      type: actions.DELETE_TECHNICIAN_ERROR,
      payload: error,
    };
  },

  setPageSize: pageSize => {
    return {
      type: actions.SET_PAGE_SIZE,
      payload: pageSize,
    };
  },
};

export default actions;
