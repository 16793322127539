const actions = {
  FETCH_INTERVENTIONS_PENDING: 'FETCH_INTERVENTIONS_PENDING',
  FETCH_INTERVENTIONS_SUCCESS: 'FETCH_INTERVENTIONS_SUCCESS',
  FETCH_INTERVENTIONS_ERROR: 'FETCH_INTERVENTIONS_ERROR',

  fetchInterventionsPending: () => {
    return {
      type: actions.FETCH_INTERVENTIONS_PENDING,
    };
  },

  fetchInterventionsSuccess: requests => {
    return {
      type: actions.FETCH_INTERVENTIONS_SUCCESS,
      payload: requests,
    };
  },

  fetchInterventionsError: error => {
    return {
      type: actions.FETCH_INTERVENTIONS_ERROR,
      payload: error,
    };
  },
};

export default actions;
