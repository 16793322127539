const actions = {
  FETCH_FAILURES_PENDING: 'FETCH_FAILURES_PENDING',
  FETCH_FAILURES_SUCCESS: 'FETCH_FAILURES_SUCCESS',
  FETCH_FAILURES_ERROR: 'FETCH_FAILURES_ERROR',

  fetchFailuresPending: () => {
    return {
      type: actions.FETCH_FAILURES_PENDING,
    };
  },

  fetchFailuresSuccess: requests => {
    return {
      type: actions.FETCH_FAILURES_SUCCESS,
      payload: requests,
    };
  },

  fetchFailuresError: error => {
    return {
      type: actions.FETCH_FAILURES_ERROR,
      payload: error,
    };
  },
};

export default actions;
