import actions from './actions';

const initState = {
  notifications: [],
};

export function notificationsReducer(state = initState, action) {
  switch (action.type) {
    case actions.SEND_NOTIFICATION:
      const notifications = [...state.notifications];
      notifications.push(action.payload);
      return {
        ...state,
        notifications: notifications,
      };
    case actions.DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: [...state.notifications].filter(
          notification =>
            JSON.stringify(notification) !== JSON.stringify(action.payload)
        ),
      };
    default:
      return state;
  }
}

export const getNotifications = state => state.notifications;
