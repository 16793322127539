import actions from './actions';

const initState = {
  pending: true,
  failures: [],
  error: null,
};

export function failuresReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_FAILURES_PENDING:
      return { ...state, pending: true };
    case actions.FETCH_FAILURES_SUCCESS:
      return {
        ...state,
        pending: false,
        failures: action.payload,
      };
    case actions.FETCH_FAILURES_ERROR:
      return {
        ...state,
        failures: initState.failures,
        error: action.payload,
      };
    default:
      return state;
  }
}

export const getFailures = state => state.failures;
export const getFailuresPending = state => state.pending;
export const getFailuresError = state => state.error;
