const actions = {
  FETCH_CALLERS_PENDING: 'FETCH_CALLERS_PENDING',
  FETCH_CALLERS_SUCCESS: 'FETCH_CALLERS_SUCCESS',
  FETCH_CALLERS_ERROR: 'FETCH_CALLERS_ERROR',
  FETCH_CALLER_PENDING: 'FETCH_CALLER_PENDING',
  FETCH_CALLER_SUCCESS: 'FETCH_CALLER_SUCCESS',
  FETCH_CALLER_ERROR: 'FETCH_CALLER_ERROR',
  RESET_CALLERS_LIST: 'RESET_CALLERS_LIST',

  fetchCallersPending: () => {
    return {
      type: actions.FETCH_CALLERS_PENDING,
    };
  },

  fetchCallersSuccess: callers => {
    return {
      type: actions.FETCH_CALLERS_SUCCESS,
      payload: callers,
    };
  },

  fetchCallersError: error => {
    return {
      type: actions.FETCH_CALLERS_ERROR,
      payload: error,
    };
  },

  resetCallersList: () => {
    return {
      type: actions.RESET_CALLERS_LIST,
    };
  },

  fetchCallerPending: () => {
    return {
      type: actions.FETCH_CALLER_PENDING,
    };
  },

  fetchCallerSuccess: caller => {
    return {
      type: actions.FETCH_CALLER_SUCCESS,
      payload: caller,
    };
  },

  fetchCallerError: error => {
    return {
      type: actions.FETCH_CALLER_ERROR,
      payload: error,
    };
  },
};

export default actions;
