import { combineReducers } from 'redux';
import App from '@iso/redux/app/reducer';
import { authReducer } from './authentication/reducer';
import { requestsReducer } from './requests/reducer';
import { usersReducer } from './users/reducer';
import { techniciansReducer } from './technicians/reducer';
import { elevatorsReducer } from './elevators/reducer';
import { failuresReducer } from './failures/reducer';
import { interventionsReducer } from './interventions/reducer';
import { callersReducer } from './callers/reducer';
import { notificationsReducer } from './notifications/reducer';
import ThemeSwitcher from '@iso/redux/themeSwitcher/reducer';
import Ecommerce from '@iso/redux/ecommerce/reducer';
import LanguageSwitcher from '@iso/redux/languageSwitcher/reducer';

export default combineReducers({
  authReducer,
  requestsReducer,
  usersReducer,
  techniciansReducer,
  elevatorsReducer,
  callersReducer,
  failuresReducer,
  interventionsReducer,
  notificationsReducer,
  App,
  ThemeSwitcher,
  Ecommerce,
  LanguageSwitcher,
});
