import actions from './actions';

const initState = {
  pageSize: 10,
  pending: false,
  elevators: [],
  elevatorsSearched: null,
  search: false,
  error: null,
  elevator: null,
  createPending: false,
  createdElevator: null,
};

export function elevatorsReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_ELEVATORS_PENDING:
      if (action.payload) {
        return { ...state, search: true, pending: true };
      } else {
        return { ...state, search: false, pending: true };
      }
    case actions.FETCH_ELEVATORS_SUCCESS:
      return {
        ...state,
        pending: false,
        search: false,
        elevators: action.payload.results,
        total: action.payload.count,
      };
    case actions.FETCH_ELEVATORS_ERROR:
      return {
        ...state,
        elevators: [],
        elevatorsSearched: null,
        error: action.payload,
        search: false,
      };

    case actions.FETCH_ELEVATOR_PENDING:
      return { ...state, search: false, elevatorPending: true };

    case actions.FETCH_ELEVATOR_SUCCESS:
      return {
        ...state,
        elevatorPending: false,
        elevator: action.payload,
      };

    case actions.FETCH_ELEVATOR_ERROR:
      return {
        ...state,
        elevators: [],
        elevatorsSearched: null,
        error: action.payload,
      };

    case actions.CREATE_ELEVATOR_PENDING:
      return { ...state, createPending: true };

    case actions.CREATE_ELEVATOR_SUCCESS:
      return {
        ...state,
        createPending: false,
        createdElevator: action.payload,
      };

    case actions.CREATE_ELEVATOR_ERROR:
      return {
        ...state,
        createPending: false,
        createError: action.payload,
        createdElevator: null,
      };

    case actions.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };

    default:
      return state;
  }
}

export const getPageSize = state => state.pageSize;
export const getElevators = state => state.elevators;
export const getElevatorsPending = state => state.pending;
export const getElevatorsSearched = state => state.elevatorsSearched;
export const getElevatorsSearch = state => state.search;
export const getElevatorsError = state => state.error;
export const getCreateElevatorPending = state => state.createPending;
export const getCreateElevatorError = state => state.createError;
export const getCreatedElevator = state => state.createdElevator;
export const getTotalRequest = state => state.total;
export const getElevator = state => state.elevator;
export const getElevatorError = state => state.error;
export const getElevatorPending = state => state.pending;
