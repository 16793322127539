import actions from './actions';

const initState = {
  pageSize: 10,
  pending: true,
  technicians: [],
  techniciansSearched: null,
  search: false,
  error: null,
  technician: null,
  createPending: false,
  createdTechnician: null,
  deletedTechnician: null,
  deletePending: null,
  deleteError: null,
};

export function techniciansReducer(state = initState, action) {
  switch (action.type) {
    case actions.FETCH_TECHNICIANS_PENDING:
      if (action.payload) {
        return { ...state, search: true, pending: true };
      } else {
        return { ...state, search: false, pending: true };
      }
    case actions.FETCH_TECHNICIANS_SUCCESS:
      return {
        ...state,
        pending: false,
        search: false,
        technicians: action.payload.results,
        total: action.payload.count,
      };
    case actions.FETCH_TECHNICIANS_ERROR:
      return {
        ...state,
        technicians: [],
        techniciansSearched: null,
        error: action.payload,
        search: false,
      };

    case actions.FETCH_TECHNICIAN_PENDING:
      return { ...state, search: false, technicianPending: true };

    case actions.FETCH_TECHNICIAN_SUCCESS:
      return {
        ...state,
        technicianPending: false,
        technician: action.payload,
      };

    case actions.FETCH_TECHNICIAN_ERROR:
      return {
        ...state,
        technicians: [],
        techniciansSearched: null,
        error: action.payload,
      };

    case actions.CREATE_TECHNICIAN_PENDING:
      return { ...state, createPending: true };

    case actions.CREATE_TECHNICIAN_SUCCESS:
      return {
        ...state,
        createPending: false,
        createdTechnician: action.payload,
      };

    case actions.CREATE_TECHNICIAN_ERROR:
      return {
        ...state,
        createPending: false,
        createError: action.payload,
        createdTechnician: null,
      };

    case actions.DELETE_TECHNICIAN_PENDING:
      return { ...state, deletePending: true };

    case actions.DELETE_TECHNICIAN_SUCCESS:
      return {
        ...state,
        deletePending: false,
        deletedTechnician: 'success',
      };

    case actions.DELETE_TECHNICIAN_ERROR:
      return {
        ...state,
        deletePending: false,
        deleteError: action.payload,
        deletedTechnician: null,
      };

    case actions.SET_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };

    default:
      return state;
  }
}

export const getPageSize = state => state.pageSize;
export const getTechnicians = state => state.technicians;
export const getTechniciansPending = state => state.pending;
export const getTechniciansSearched = state => state.techniciansSearched;
export const getTechniciansSearch = state => state.search;
export const getTechniciansError = state => state.error;
export const getCreateTechnicianPending = state => state.createPending;
export const getCreateTechnicianError = state => state.createError;
export const getCreatedTechnician = state => state.createdTechnician;
export const getTotalTechnicians = state => state.total;
export const getTechnician = state => state.technician;
export const getTechnicianError = state => state.error;
export const getTechnicianPending = state => state.pending;

export const getDeletedTechnician = state => state.deletedTechnician;
export const getDeleteTechnicianError = state => state.deleteError;
export const getDeleteTechnicianPending = state => state.deletePending;
